.footer { 
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto 10vh;
    gap: 20px;
    padding: 40px 20px;
    box-shadow: 0 2px 10px 0 rgb(207, 207, 207);
    color: black;
    text-align: center; /* General alignment for content */
    margin-top: 20vh;
}

/* Footer Sections */
#footer-hours, #footer-contact {
    font-family: var(--primary-font-family);
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 5px 0 rgb(207, 207, 207); /* Adds depth to sections */
}

#footer-hours h1, #footer-contact h1 {
    font-family: var(--primary-font-family);
    font-size: 22px;
    color: #a80000;
    margin-bottom: 15px;
    text-transform: uppercase; /* Makes headings bold and clear */
    border-bottom: 2px solid #a80000; /* Heading underline */
    padding-bottom: 5px;
}

/* Weekly Hours Styling */
.footer-hours-content {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.footer-hour-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    border-bottom: 1px solid #eee; /* Soft divider for each row */
}

.footer-hour-li:last-child {
    border-bottom: none;
}

.footer-hour-li span {
    font-weight: bold;
    color: #a80000;
}

.footer-hour-li p {
    color: #555;
    font-size: 14px;
}

/* Copyright Section */
.copyright {
    grid-row: 2;
    grid-column: span 2;
    padding-top: 20px;
    font-size: 12px;
    color: grey;
    margin-top: 20px;
}

/* Mobile Adjustments */
@media (max-width: 600px) {
    .footer {
        grid-template-columns: 1fr; /* Single column layout */
        grid-template-rows: auto auto auto;
    }

    #footer-hours {
        grid-column: 1;
        grid-row: 1;
    }

    #footer-contact {
        grid-column: 1;
        grid-row: 2;
    }

    .copyright {
        grid-row: 3;
        grid-column: span;
        font-size: 10px;
    }
}
